<template>
    <div>
        <div class="header pb-8 pt-2 pt-lg-6 d-flex align-items-center">
            <!-- Mask -->
            
            <!-- Header container -->
            <div class="container-fluid d-flex align-items-center">

                <div class="col-lg-7 p-0 col-md-10">

                </div>
            </div>
        </div>

        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col-xl-6 order-xl-1">
                    <a-card shadow type="secondary">
                        <div class="header bg-white border-0">
                            <div class="row align-items-center">
                                <div class="col-8">
                                    <h3 class="mb-0">Cena dopravy</h3>
                                </div>
                                <div class="col-4 text-right">
                                </div>
                            </div>
                        </div>
                        <template>
                        <form @submit.prevent="handleSubmit">
                            <div :key="hotel.id" class="row " v-for="hotel in data">
                                <div class="pl-lg-2 pt-3 head" style=""><h4>{{hotel.title}}</h4>
                                </div>
                                <span class="">
                            <div class="p" >
    <a-input alternative="" :key="hotel.id" input-classes="form-control" style="display:inline-block;margin-right:10px;" v-model="prices[hotel.id]"/> €
                         </div>
                                </span>
                            </div>
                            <div class="text-right">
                                <button class="btn btn-primary">Uložiť</button>
                            </div>

                        </form>
                        </template>
                    </a-card>

                </div>  </div>
        </div>  </div>
</template>
<script>

    import {getURL} from "../../api";
    import {dataService} from "../../_services";

    export default {
        name: "shippingprices",
        components: {
        },
        data() {
            return {
                url: getURL(),
                data: "",
                prices: {},
                colorsto: JSON.parse(localStorage.colors),
                days: [
                    {"value": '1', "label": '1 deň'},
                    {"value": '2', "label": '2 dni'},
                    {"value": '3', "label": '3 dni'},
                    {"value": '4', "label": '4 dni'},
                    {"value": '5', "label": '5 dní'},
                    {"value": '6', "label": '6 dni'},
                    {"value": '7', "label": '7 dní'},
                    {"value": '8', "label": '8 dní'},
                    {"value": '9', "label": '9 dní'},
                    {"value": '10', "label": '10 dní'},
                    {"value": '11', "label": 'Víkend'},
                ],

            }
        },
        methods: {

            handleSubmit() {
                let $user_data = this.prices;

                dataService.axiosPost(this.$options.name, $user_data);
            },

        },
        mounted() {
            dataService.axiosFetch('gethotellist').then(results => {
              this.data = results;
              this.loading = false;
            });

            dataService.axiosFetch('getshippingprices').then(results => {
                let roleops = {};
                results.forEach(obj => { roleops[obj.id] = obj.price; });
                this.prices = roleops;

            });


        }
    }
</script>

<style scoped>
    .image-row
    {min-height:100px; display:inline-flex;width:100%;margin:0;margin-top:0px; padding-bottom:20px; margin-bottom:15px;border-bottom:10px solid #f7f8fd;}
    .room-image
    { border-radius:5px;}
    .par{
        float:left;
        margin:0.5%;
        width:19%;
    }
    .head h4
    {
        color: #5da2ce;
    }
    .head img
    {width:100%}
    h4.par{
        max-width: 200px;}
    .p
    {width:100%;display:inline-block;}
    .head .nav-item
    {width:100%;}
    .head img
    {width:100%}
    .head
    {width:14%;float:left;padding-right:1%;}
    .params
    {width:83%; padding:15px;float:left;background-color: #f5f5f5;}
</style>

